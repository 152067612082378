<template>
  <div id="antdVue" class="antdVue" :style="themeColorInit(themeColorConfig)">
      <!---栅格布局 布局开始-->
      <a-row class="benben-grid  heartdataStatisticsretentionRate_flex_0">


          <a-col :span="24">

          </a-col>



          <a-col :span="18">

          </a-col>



          <a-col :span="6">

          </a-col>


      </a-row>
      <!---栅格布局 布局结束-->

      <!---栅格布局 布局开始-->
      <a-row class="benben-grid  heartdataStatisticsretentionRate_flex_1">


          <a-col :span="24">
              <a-form-model ref="formRef1686651924054" class="benben-flex-form heartdataStatisticsretentionRate_fd1_0_c0" label-align="right" layout="horizontal" :label-col="{span:4,offset:0}" :wrapper-col="{span:20,offset:0}">
                  <div class="flex flex-wrap align-center flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c0" isAlone="false">
                      <a-form-model-item need="false" :rules="[]" :colon="true" label="记录日期">
                          <a-config-provider :locale="locale">
                              <range-picker class="heartdataStatisticsretentionRate_fd1_0_c0_c0_c0" :disabled="false" :allow-clear="true" placeholder="请选择" size="default" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" :show-time="true">

                              </range-picker>
                          </a-config-provider>
                      </a-form-model-item>
                      <a-button :disabled="false" type="primary">搜索</a-button>

                      <a-button :disabled="false">重置</a-button>


                  </div>

                  <a-button class="heartdataStatisticsretentionRate_fd1_0_c0_c1" :disabled="false" type="primary">导出</a-button>

                  <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2" isAlone="false">
                      <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0" isAlone="false">
                          <div class="flex flex-wrap align-center justify-between heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c0" isAlone="false">
                              <div class="flex flex-wrap align-center justify-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c0_c0" isAlone="false">

                                  <span>记录日期</span>

                              </div>

                              <div class="flex flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c0_c1" isAlone="false">

                                  <span>次日留存率</span>

                              </div>

                              <div class="flex flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c0_c2" isAlone="false">

                                  <span>3日留存率</span>

                              </div>

                              <div class="flex flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c0_c3" isAlone="false">

                                  <span>4日留存率</span>

                              </div>

                              <div class="flex flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c0_c4" isAlone="false">

                                  <span>100日留存率</span>

                              </div>


                          </div>

                          <div class="flex flex-wrap align-center justify-between heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1" isAlone="false">
                              <div class="flex flex-wrap align-center justify-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c0" isAlone="false">
                                  <div class="flex flex-direction flex-wrap align-center self-center justify-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c0_c0" isAlone="false">

                                      <span class="heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c0_c0_c0">2023-06-14</span>

                                  </div>


                              </div>

                              <div class="flex flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c1" isAlone="false">

                                  <span>1000</span>

                              </div>

                              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c2" isAlone="false">

                                  <span>11000</span>

                              </div>

                              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c3" isAlone="false">

                                  <span>1000</span>

                              </div>

                              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c4" isAlone="false">

                                  <span>1000000</span>

                              </div>


                          </div>

                          <div class="flex flex-wrap align-center justify-between heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2" isAlone="false">
                              <div class="flex flex-wrap align-center justify-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c0" isAlone="false">
                                  <div class="flex flex-direction flex-wrap align-center self-center justify-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c0_c0" isAlone="false">

                                      <span class="heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c0_c0_c0">2023-06-15</span>

                                  </div>


                              </div>

                              <div class="flex flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c1" isAlone="false">

                                  <span>1000</span>

                              </div>

                              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c2" isAlone="false">

                                  <span>10000</span>

                              </div>

                              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c3" isAlone="false">

                                  <span class="heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c3_c0">1000</span>

                              </div>

                              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c4" isAlone="false">

                                  <span>1000000</span>

                              </div>


                          </div>

                          <div class="flex flex-wrap align-center justify-between heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3" isAlone="false">
                              <div class="flex flex-wrap align-center justify-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c0" isAlone="false">
                                  <div class="flex flex-direction flex-wrap align-center self-center justify-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c0_c0" isAlone="false">

                                      <span class="heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c0_c0_c0">2023-05-16</span>

                                  </div>


                              </div>

                              <div class="flex flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c1" isAlone="false">

                                  <span>1000</span>

                              </div>

                              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c2" isAlone="false">

                                  <span>1000</span>

                              </div>

                              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c3" isAlone="false">

                                  <span>895455</span>

                              </div>

                              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c4" isAlone="false">

                                  <span>1000000</span>

                              </div>


                          </div>

                          <div class="flex flex-wrap align-center justify-between heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4" isAlone="false">
                              <div class="flex flex-wrap align-center justify-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c0" isAlone="false">
                                  <div class="flex flex-direction flex-wrap align-center self-center justify-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c0_c0" isAlone="false">

                                      <span class="heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c0_c0_c0">2023-10-22</span>

                                  </div>


                              </div>

                              <div class="flex flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c1" isAlone="false">

                                  <span>1000</span>

                              </div>

                              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c2" isAlone="false">

                                  <span>10000</span>

                              </div>

                              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c3" isAlone="false">

                                  <span>13233220</span>

                              </div>

                              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c4" isAlone="false">

                                  <span>1000000</span>

                              </div>


                          </div>


                      </div>


                  </div>

              </a-form-model>

          </a-col>

          <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_1" isAlone="false">
              <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0" isAlone="false">
                  <div class="flex flex-wrap align-center justify-between heartdataStatisticsretentionRate_fd1_1_c0_c0" isAlone="false">
                      <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c0_c0" isAlone="false">

                      </div>


                  </div>

                  <div class="flex flex-wrap align-center justify-between heartdataStatisticsretentionRate_fd1_1_c0_c1" isAlone="false">
                      <div class="flex flex-wrap align-center justify-center heartdataStatisticsretentionRate_fd1_1_c0_c1_c0" isAlone="false">
                          <div class="flex flex-direction flex-wrap align-center self-center justify-center heartdataStatisticsretentionRate_fd1_1_c0_c1_c0_c0" isAlone="false">

                              <span class="heartdataStatisticsretentionRate_fd1_1_c0_c1_c0_c0_c0">已处理</span>

                          </div>


                      </div>

                      <div class="flex flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c1_c1" isAlone="false">

                          <span>2023年实用新型办公设备采购合同</span>

                      </div>

                      <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c1_c2" isAlone="false">

                          <span>WAE-12340000</span>

                      </div>

                      <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c1_c3" isAlone="false">

                          <span class="heartdataStatisticsretentionRate_fd1_1_c0_c1_c3_c0"></span>

                          <span class="heartdataStatisticsretentionRate_fd1_1_c0_c1_c3_c1">合同履约风险</span>

                      </div>

                      <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c1_c4" isAlone="false">

                          <span>2023-01-18</span>

                      </div>


                  </div>

                  <div class="flex flex-wrap align-center justify-between heartdataStatisticsretentionRate_fd1_1_c0_c2" isAlone="false">
                      <div class="flex flex-wrap align-center justify-center heartdataStatisticsretentionRate_fd1_1_c0_c2_c0" isAlone="false">
                          <div class="flex flex-direction flex-wrap align-center self-center justify-center heartdataStatisticsretentionRate_fd1_1_c0_c2_c0_c0" isAlone="false">

                              <span class="heartdataStatisticsretentionRate_fd1_1_c0_c2_c0_c0_c0">未处理</span>

                          </div>


                      </div>

                      <div class="flex flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c2_c1" isAlone="false">

                          <span>2023年实用新型办公设备采购合同</span>

                      </div>

                      <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c2_c2" isAlone="false">

                          <span>WAE-12340000</span>

                      </div>

                      <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c2_c3" isAlone="false">

                          <span class="heartdataStatisticsretentionRate_fd1_1_c0_c2_c3_c0"></span>

                          <span class="heartdataStatisticsretentionRate_fd1_1_c0_c2_c3_c1">合同履约风险</span>

                      </div>

                      <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c2_c4" isAlone="false">

                          <span>2023-01-18</span>

                      </div>


                  </div>

                  <div class="flex flex-wrap align-center justify-between heartdataStatisticsretentionRate_fd1_1_c0_c3" isAlone="false">
                      <div class="flex flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c3_c0" isAlone="false">

                      </div>

                      <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c3_c1" isAlone="false">

                          <span>WAE-12340000</span>

                      </div>

                      <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c3_c2" isAlone="false">

                          <span class="heartdataStatisticsretentionRate_fd1_1_c0_c3_c2_c0"></span>

                          <span class="heartdataStatisticsretentionRate_fd1_1_c0_c3_c2_c1">合同履约风险</span>

                      </div>

                      <div class="flex  flex-wrap align-center heartdataStatisticsretentionRate_fd1_1_c0_c3_c3" isAlone="false">

                          <span>2023-01-18</span>

                      </div>


                  </div>


              </div>


          </div>



          <a-col :span="6">

          </a-col>


      </a-row>
      <!---栅格布局 布局结束-->



  </div>
</template>

<script>
  import {
      receive,
      benbenSelectDiy,
      benbenFlexNumberBox,
      scrollList,
      treeSelect,
      simpleSelect,
      multipleSelect,
      cascaderForm,
      checkboxForm,
      switchForm,
      messageReply,
      rangePicker,
      handSignature,
      mixinAddTableItem,
      editorForm,
      qiunDataCharts,
      copyText,
      mixinTableItemChange,
      in_array,
      themeColorInit,
      getUrlKey,
      clearDataPass,
      isEmpty
  } from '@/assets/diypaaspc/diy-pass-pc.js'
  import {
      provinceForm
  } from '@/assets/diypaaspc/province_area.js'
  import request from '@/utils/request'
  export default {
      name: "goods",
      components: {
          qiunDataCharts,
          benbenSelectDiy,
          //            productSelect, 
          benbenFlexNumberBox,
          scrollList,
          treeSelect,
          simpleSelect,
          messageReply,
          rangePicker,
          multipleSelect,
          provinceForm,
          editorForm,
          cascaderForm,
          switchForm,
          checkboxForm,
          //            uploadImage,
          //            uploadFile,
          //            uploadMedia,
          //            uploadSingleFile,
          handSignature
      },
      data() {
          receive(this);
          const themeColorConfig = {
              "presetApi": {
                  "getClassifyOne": "",
                  "getClassifyTow": "",
                  "getClassifyShop": ""
              },
              "themeType": 0,
              "themeConfig": [{
                  "colorConfig": ["#333333", "#666666", "#999999", "#ffffff", "#1178F0", "#F02325", "#FC9C3A", "#4DC777", "#F2CC98", "#F2CC66"],
                  "bgColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#FC9fff", "#E6F333", "#E6F1FE", "linear-gradient(301deg, #EBBA7B 0%, #F8D9AD 100%)", "linear-gradient(180deg, #F5588E 0%, #F0403C 100%)", "linear-gradient(179deg, #FFD033 0%, #F4890E 100%)"],
                  "bdColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#333333", "#666666", "#999999", "#dddddd", "#1178F0", "#F02325"]
              }]
          };
          return {
              themeColorConfig,
          };

      },
      computed: {

      },
      created() {

      },
      mounted() {

      },
      beforeDestroy() {

      },
      watch: {},
      methods: {
          copyText,
          mixinTableItemChange,
          in_array,
          themeColorInit,
          isEmpty,

      }
  }
</script>

<style lang="less" scoped>
  @import 'https://zhongtai.zhongbenruanjian.com/static/diypaaspc/css/diy-pass-pc.css';

  .antdVue {
      width: 100%;
      background: #F8F8F8;
      background-size: 100% auto !important;
  }

  #antdVue .heartdataStatisticsretentionRate_flex_0 {
      background: #fff;
      width: 100%;
  }

  #antdVue .heartdataStatisticsretentionRate_flex_1 {
      background: #fff;
      width: 100%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c3_c3 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c3_c2_c1 {
      color: var(--benbenFontColor6);
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c3_c2_c0 {
      background: rgba(252, 156, 58, 1);
      color: rgba(71, 129, 245, 0);
      width: 6px;
      height: 6px;
      border-radius: 16px 16px 16px 16px;
      margin: 0px 5px 0px 0px;
      background-size: 100% auto !important;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c3_c2 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c3_c1 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c3_c0 {
      width: 30%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c3 {
      width: 100%;
      height: 50px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c2_c4 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c2_c3_c1 {
      color: rgba(255, 0, 0, 1);
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c2_c3_c0 {
      background: rgba(255, 0, 0, 1);
      color: rgba(71, 129, 245, 0);
      width: 6px;
      height: 6px;
      border-radius: 16px 16px 16px 16px;
      margin: 0px 5px 0px 0px;
      background-size: 100% auto !important;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c2_c3 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c2_c2 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c2_c1 {
      width: 30%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c2_c0_c0_c0 {
      color: rgba(255, 0, 0, 1);
      font-size: 12px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c2_c0_c0 {
      border: 1px solid rgba(255, 0, 0, 1);
      height: 23px;
      padding: 0px 10px 0px 10px;
      border-radius: 16px 16px 16px 16px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c2_c0 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c2 {
      border-bottom: 1px solid #eee;
      width: 100%;
      height: 50px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c1_c4 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c1_c3_c1 {
      color: #4781f5;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c1_c3_c0 {
      background: #4781f5;
      color: rgba(71, 129, 245, 0);
      width: 6px;
      height: 6px;
      border-radius: 16px 16px 16px 16px;
      margin: 0px 5px 0px 0px;
      background-size: 100% auto !important;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c1_c3 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c1_c2 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c1_c1 {
      width: 30%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c1_c0_c0_c0 {
      color: #4781f5;
      font-size: 12px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c1_c0_c0 {
      border: 1px solid var(--benbenbdColor2);
      height: 23px;
      padding: 0px 10px 0px 10px;
      border-radius: 16px 16px 16px 16px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c1_c0 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c1 {
      border-bottom: 1px solid #eee;
      width: 100%;
      height: 50px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c0_c0 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0_c0 {
      border-bottom: 1px solid #eee;
      width: 100%;
      height: 50px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1_c0 {
      width: 100%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_1 {
      background: rgba(255, 255, 255, 1);
      width: 100%;
      padding: 0px 0px 15px 0px;
      background-size: 100% auto !important;
      border-radius: 6px 6px 6px 6px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c4 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c3 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c2 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c1 {
      width: 30%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c0_c0_c0 {
      color: var(--benbenFontColor0);
      font-size: 12px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c0_c0 {
      height: 23px;
      padding: 0px 10px 0px 10px;
      border-radius: 16px 16px 16px 16px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4_c0 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c4 {
      width: 100%;
      height: 50px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c4 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c3 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c2 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c1 {
      width: 30%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c0_c0_c0 {
      color: var(--benbenFontColor0);
      font-size: 12px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c0_c0 {
      height: 23px;
      padding: 0px 10px 0px 10px;
      border-radius: 16px 16px 16px 16px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3_c0 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c3 {
      border-bottom: 1px solid #eee;
      width: 100%;
      height: 50px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c4 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c3_c0 {
      color: var(--benbenFontColor0);
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c3 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c2 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c1 {
      width: 30%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c0_c0_c0 {
      color: var(--benbenFontColor0);
      font-size: 12px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c0_c0 {
      height: 23px;
      padding: 0px 10px 0px 10px;
      border-radius: 16px 16px 16px 16px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2_c0 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c2 {
      border-bottom: 1px solid #eee;
      width: 100%;
      height: 50px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c4 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c3 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c2 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c1 {
      width: 30%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c0_c0_c0 {
      color: var(--benbenFontColor0);
      font-size: 12px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c0_c0 {
      height: 23px;
      padding: 0px 10px 0px 10px;
      border-radius: 16px 16px 16px 16px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1_c0 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c1 {
      border-bottom: 1px solid #eee;
      width: 100%;
      height: 50px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c0_c4 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c0_c3 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c0_c2 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c0_c1 {
      width: 30%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c0_c0 {
      width: 15%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0_c0 {
      border-bottom: 1px solid #eee;
      background: rgba(248, 248, 248, 1);
      width: 100%;
      margin: 0px 0px 0px 0px;
      font-weight: 700;
      height: 50px;
      background-size: 100% auto !important;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2_c0 {
      width: 100%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c2 {
      background: rgba(255, 255, 255, 1);
      width: 100%;
      background-size: 100% auto !important;
      border-radius: 6px 6px 6px 6px;
      padding: 15px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c1 {
      margin: 0px 0px 0px 15px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c0_c0 {
      width: 100%;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0_c0 {
      margin: 15px 15px 0px 15px;
  }

  #antdVue .heartdataStatisticsretentionRate_fd1_0_c0 {
      background: #fff;
      width: 100%;
      min-height: 100px;
  }
</style>